import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { TTasksData, TTrustsData } from "../../../utilities/trustTypes";
import styled from "styled-components";
import { getDefaultTasksData } from "../../../utilities/defaultDataTypes";

export const defaultTasksData: TTasksData = getDefaultTasksData();

const StyledHeadlineH5 = styled.h5`
  color: #4c5637;
  font-weight: bold;
`;

export default function Tasks({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const tasksData = formData.tasks ?? defaultTasksData;

  const updateTasksTabData = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: string,
    section: "tasks" | "fees" | "trust"
  ) => {
    if (readOnly) return;

    let value: string | boolean = event.currentTarget.value;

    if (event.currentTarget.type === "checkbox") {
      value = (event as React.ChangeEvent<HTMLInputElement>).currentTarget
        .checked;
    }
    // if (!formData.tasks) return;

    const updatedSection = {
      ...formData[section],
      [key]: value,
    };

    setFormData({
      ...formData,
      [section]: updatedSection,
      tasks: {
        ...tasksData,
        [key]: value,
      },
    });
  };
  // const updateCurrentTasks = (
  //   event: React.ChangeEvent<
  //     HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  //   >
  //   // key: keyof TTasksData
  // ) => {
  //   if (readOnly) return;
  //   let value: string | boolean = event.currentTarget.value;

  //   // const fees = formData.fees;

  //   if (value === "true") {
  //     value = true;
  //   } else if (value === "false") {
  //     value = false;
  //   }

  //   // if (!formData.tasks) return;

  //   const updatedTasks = {
  //     ...formData.fees,
  //     //   [key]: value,
  //   };

  //   setFormData({
  //     ...formData,
  //     fees: updatedTasks,
  //   });
  // };
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="notes">
            <Form.Label>Trust Notes</Form.Label>
            <Form.Control
              name="notes"
              as="textarea"
              rows={4}
              value={formData.trust?.notes}
              onChange={(target) =>
                updateTasksTabData(target, "notes", "trust")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <StyledHeadlineH5>Tasks</StyledHeadlineH5>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isClientTexted"
              type="checkbox"
              label="Texted client from (619) 701-2764 to open a 'File' of Data"
              checked={formData.tasks?.isClientTexted || false}
              onChange={(e) => updateTasksTabData(e, "isClientTexted", "tasks")}
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isNewClientEmailed"
              type="checkbox"
              label="Emailed the new client data form fillable PDF"
              checked={formData.tasks?.isNewClientEmailed || false}
              onChange={(e) =>
                updateTasksTabData(e, "isNewClientEmailed", "tasks")
              }
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isDataInput"
              type="checkbox"
              label="Data input to program SPT, CPT, TA, or Amendment, COT (Certificate of Trust)"
              checked={formData.tasks?.isDataInput || false}
              onChange={(e) => updateTasksTabData(e, "isDataInput", "tasks")}
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isDraftSent"
              type="checkbox"
              label="Draft sent by email for client to proof"
              checked={formData.tasks?.isDraftSent || false}
              onChange={(e) => updateTasksTabData(e, "isDraftSent", "tasks")}
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isDateOfTrustSet"
              type="checkbox"
              label="N18 calendar set with date of trust"
              checked={formData.tasks?.isDateOfTrustSet || false}
              onChange={(e) =>
                updateTasksTabData(e, "isDateOfTrustSet", "tasks")
              }
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isNotarized"
              type="checkbox"
              label="Notarized"
              checked={formData.tasks?.isNotarized || false}
              onChange={(e) => updateTasksTabData(e, "isNotarized", "tasks")}
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isVideoCompleted"
              type="checkbox"
              label="Video Completed"
              checked={formData.tasks?.isVideoCompleted || false}
              onChange={(e) =>
                updateTasksTabData(e, "isVideoCompleted", "tasks")
              }
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Group>
            <Form.Check
              name="isFeePaid"
              type="checkbox"
              label="Fee Paid"
              checked={formData.tasks?.isFeePaid || false}
              onChange={(e) => updateTasksTabData(e, "isFeePaid", "tasks")}
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      {formData.tasks.isFeePaid && (
        <>
          <Row className="m-3">
            <Col xs={3}>
              <Form.Group controlId="paymentDate">
                <Form.Label>Payment Date</Form.Label>
                <Form.Control
                  name="paymentDate"
                  type="date"
                  value={formData.fees?.paymentDate}
                  onChange={(target) =>
                    updateTasksTabData(target, "paymentDate", "fees")
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group controlId="amountPaid">
                <Form.Label>Total Paid</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    name="paid"
                    type="text"
                    value={formData.fees?.paid}
                    onChange={(target) =>
                      updateTasksTabData(target, "paid", "fees")
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="paymentType">
                <Form.Label className="mx-2">Payment Type</Form.Label>
                <Form.Group>
                  <Form.Check
                    inline
                    name="paymentType"
                    type="radio"
                    label="Cash"
                    value="cash"
                    checked={formData.fees?.paymentType === "cash"}
                    onChange={(e) =>
                      updateTasksTabData(e, "paymentType", "fees")
                    }
                  ></Form.Check>
                  <Form.Check
                    inline
                    name="paymentType"
                    type="radio"
                    label="Credit Card"
                    value="credit card"
                    checked={formData.fees?.paymentType === "credit card"}
                    onChange={(e) =>
                      updateTasksTabData(e, "paymentType", "fees")
                    }
                  ></Form.Check>
                  <Form.Check
                    inline
                    name="paymentType"
                    type="radio"
                    label="Check"
                    value="check"
                    checked={formData.fees?.paymentType === "check"}
                    onChange={(e) =>
                      updateTasksTabData(e, "paymentType", "fees")
                    }
                  ></Form.Check>
                  <Form.Check
                    inline
                    name="paymentType"
                    type="radio"
                    label="ACH Transfer"
                    value="ach transfer"
                    checked={formData.fees?.paymentType === "ach transfer"}
                    onChange={(e) =>
                      updateTasksTabData(e, "paymentType", "fees")
                    }
                  ></Form.Check>
                  <Form.Check
                    inline
                    name="paymentType"
                    type="radio"
                    label="Venmo"
                    value="venmo"
                    checked={formData.fees?.paymentType === "venmo"}
                    onChange={(e) =>
                      updateTasksTabData(e, "paymentType", "fees")
                    }
                  ></Form.Check>
                  <Form.Check
                    inline
                    name="paymentType"
                    type="radio"
                    label="Cash App"
                    value="cash app"
                    checked={formData.fees?.paymentType === "cash app"}
                    onChange={(e) =>
                      updateTasksTabData(e, "paymentType", "fees")
                    }
                  ></Form.Check>
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={3}>
              <Form.Group>
                <Form.Check
                  name="isDeedRecorded"
                  type="checkbox"
                  label="Deed Recorded"
                  checked={formData.tasks?.isDeedRecorded || false}
                  onChange={(e) =>
                    updateTasksTabData(e, "isDeedRecorded", "tasks")
                  }
                ></Form.Check>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      {formData.tasks.isDeedRecorded && (
        <Row className="m-3">
          <Col xs={3}>
            <Form.Group className="mb-3" controlId="deedRecordDate">
              <Form.Label>Deed Record Date</Form.Label>
              <Form.Control
                name="deedRecordDate"
                type="date"
                value={formData.tasks?.deedRecordedDate}
                onChange={(target) =>
                  updateTasksTabData(target, "deedRecordedDate", "tasks")
                }
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isDeedEmailedToClient"
              type="checkbox"
              label="Deed HMSTD, Receipt, PCOR Emailed to Client"
              checked={formData.tasks?.isDeedEmailedToClient || false}
              onChange={(e) =>
                updateTasksTabData(e, "isDeedEmailedToClient", "tasks")
              }
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={3}>
          <Form.Group>
            <Form.Check
              name="isDeedMailedToClient"
              type="checkbox"
              label="Deed USPSd to Client"
              checked={formData.tasks?.isDeedMailedToClient || false}
              onChange={(e) =>
                updateTasksTabData(e, "isDeedMailedToClient", "tasks")
              }
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
      {formData.tasks.isDeedMailedToClient && (
        <Row className="m-3">
          <Col xs={3}>
            <Form.Group className="mb-3" controlId="deedMailedDate">
              <Form.Label>Date Deed Mailed</Form.Label>
              <Form.Control
                name="deedMailedDate"
                type="date"
                value={formData.tasks?.deedMailedDate}
                onChange={(target) =>
                  updateTasksTabData(target, "deedMailedDate", "tasks")
                }
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              name="isBeneficiaryUpdated"
              type="checkbox"
              label="POD/TOD/Beneficiary Updated on Bank, Credit Union, Broker Accounts & Life, IRA Accounts"
              checked={formData.tasks?.isBeneficiaryUpdated || false}
              onChange={(e) =>
                updateTasksTabData(e, "isBeneficiaryUpdated", "tasks")
              }
            ></Form.Check>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
