import { useCallback, useState } from "react";
import {
  TemplateDownloadEndpoints,
  TemplateTypes,
} from "../../utilities/types";

export default function useGetDownloadTemplateData() {
  const [isLoadingDownloadTemplate, setIsLoadingDownloadTemplate] =
    useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchDownloadTemplate = useCallback(async (templateType: string) => {
    let urlEndpoint = "";

    switch (templateType) {
      case "SPT":
        urlEndpoint = TemplateDownloadEndpoints.SPT;
        break;
      case "Avery Labels SPT":
        urlEndpoint = TemplateDownloadEndpoints.AveryLabelsSPT;
        break;
      case "Trust Certificate SPT":
        urlEndpoint = TemplateDownloadEndpoints.TrustCertificateSPT;
        break;
      case "Trust Transfer Deed SPT":
        urlEndpoint = TemplateDownloadEndpoints.TrustTransferDeedSPT;
        break;
      case "Love Letter SPT":
        urlEndpoint = TemplateDownloadEndpoints.LoveLetterSPT;
        break;
      case "CPT":
        urlEndpoint = TemplateDownloadEndpoints.CPT;
        break;
      case "Avery Labels CPT":
        urlEndpoint = TemplateDownloadEndpoints.AveryLabelsCPT;
        break;
      case "Trust Certificate CPT":
        urlEndpoint = TemplateDownloadEndpoints.TrustCertificateCPT;
        break;
      case "Trust Transfer Deed CPT":
        urlEndpoint = TemplateDownloadEndpoints.TrustTransferDeedCPT;
        break;
      case "Love Letter CPT":
        urlEndpoint = TemplateDownloadEndpoints.LoveLetterCPT;
        break;
      case "TA":
        urlEndpoint = TemplateDownloadEndpoints.TA;
        break;
      case "Vial of Life":
        urlEndpoint = TemplateDownloadEndpoints.VialofLife;
        break;
      case "Binder Basics":
        urlEndpoint = TemplateDownloadEndpoints.BinderBasics;
        break;
      case "Trust Transfer Deed":
        urlEndpoint = TemplateDownloadEndpoints.TrustTransferDeed;
        break;
      case "Trust Certificate":
        urlEndpoint = TemplateDownloadEndpoints.TrustCertificate;
        break;
      case "Love Letter":
        urlEndpoint = TemplateDownloadEndpoints.LoveLetter;
        break;
      default:
        console.error(
          `No API endpoint available for template type ${templateType}`
        );
        return;
    }

    let url = `https://hometrustapi.azurewebsites.net${urlEndpoint}`;

    if (!url) {
      console.error(
        `No API endpoint available for template type ${TemplateTypes}`
      );
      return;
    }

    setIsLoadingDownloadTemplate(true);
    setError(null);

    console.log("getting Templates");
    try {
      await fetch(
        url, 
        { 
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }, 
          method: "GET" 
        })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = templateType;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => console.error("Error downloading file:", error));
    } catch (error: any) {
      setError(error);
      setIsLoadingDownloadTemplate(false);
    }
  }, []);

  return { isLoadingDownloadTemplate, error, fetchDownloadTemplate };
}
