import { useCallback, useState } from "react";
import { HTTPMethods, UrlPaths } from "../../utilities/types";
import { TClientData } from "../../utilities/clientTypes";
import { useLocation } from "wouter";

export default function useOneClientData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [clientData, setClientData] = useState<TClientData | null>(null);
  const [, setLocation] = useLocation();

  const oneClientData = useCallback(
    async (id: string, method: HTTPMethods, clientData?: TClientData) => {
      const url = "https://hometrustapi.azurewebsites.net/api/Clients/" + id;

      setIsLoading(true);
      setError(null);

      try {
        let response = null;
        if (method === HTTPMethods.GET || method === HTTPMethods.DELETE) {
          response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            method,
          });
        }

        if (method === HTTPMethods.PUT) {
          console.log("data in put", clientData);

          response = await fetch(url, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
            method,
            body: JSON.stringify(clientData),
          });
        }

        if (response == null) {
          throw new Error("Response is null");
        }
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        if (method === HTTPMethods.GET) {
          setClientData(await response.json());
        }
        if (method === HTTPMethods.DELETE) {
          setLocation(UrlPaths.Clients);
        }
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }

      setIsLoading(false);
    },
    [setLocation]
  );

  return { oneClientData, clientData, isLoading, error };
}
