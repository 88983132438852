import { useCallback, useState } from "react";

export default function useGetLoveLetterTemplateData() {
  const [isLoadingLoveLetterTemplate, setIsLoadingLoveLetterTemplate] =
    useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchLoveLetter = useCallback(
    async (trustId: string, trustName: string) => {
      let url = `https://hometrustapi.azurewebsites.net/api/Templates/generate/LoveLetter/${trustId}`;

      setIsLoadingLoveLetterTemplate(true);
      setError(null);

      console.log("getting Templates");
      try {
        await fetch(
          url, 
          { 
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }, 
            method: "GET" 
          })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${trustName}_Love Letter.docx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((error) => console.error("Error downloading file:", error));
      } catch (error: any) {
        setError(error);
        setIsLoadingLoveLetterTemplate(false);
      }
    },
    []
  );

  return { isLoadingLoveLetterTemplate, error, fetchLoveLetter };
}
