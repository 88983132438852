import { useCallback, useMemo, useState, useRef } from "react";
import { useAuth } from "../authHooks/auth-context";

export type TTrustsData = {
  trust: TTrustData;
};

export type TTrustData = {
  id: string;
  type: string;
  name: string;
  date: string;
  familyName: string;
  trustAka?: string;
  signingDate: string;
  // trustees: TTrusteeData[];
};

// export type TTrusteeData = {
//   id: string;
//   type: string;
//   fullName: string;
//   fullAddress: string;
// };

const PAGE_SIZE = 10;

export default function useGetTrustData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [tData, setTrustData] = useState<Array<TTrustsData> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const currentFetchId = useRef(0);
  const { token } = useAuth();

  const fetchData = useCallback(
    async (
      pageNumber: number,
      searchTerm: string | null,
      type: string | null,
      orderBy: string | null,
      ascending: boolean | null
    ) => {
      if (!token) {
        setError(new Error('No authentication token available'));
        return;
      }

      const fetchId = ++currentFetchId.current;
      let orderByValue = "modifieddate";
      let ascendingValue = false;

      let url = `https://hometrustapi.azurewebsites.net/api/Trusts?PageSize=${PAGE_SIZE}&PageNumber=${pageNumber}`;

      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      if (type !== null) {
        url += "&type=" + type;
      }

      if (orderBy !== null) {
        orderByValue = orderBy;
      }

      if (ascending !== null) {
        ascendingValue = ascending;
      }

      url += "&OrderBy=" + orderByValue + "&Ascending=" + ascendingValue;

      setIsLoading(true);
      setError(null);

      console.log("getting trusts");
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          method: "GET",
        });

        console.log(response);
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          const trustsArray =
            responsejson.trusts && Array.isArray(responsejson.trusts)
              ? responsejson.trusts
              : [];
          setTrustData(trustsArray);
          console.log(responsejson);
          const totalEntries: number = responsejson.metaData.totalEntries;
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoading(false);
        } else {
          console.log("Fetch Operation Expired");
        }
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
    },
    [token]
  );

  const trustData = useMemo(() => {
    if (tData == null) {
      console.log("tData is null");
      return [];
    }
    console.log("Mapping tData to trustData:", tData);
    return tData.map((item: TTrustsData) => ({
      id: item.trust.id,
      type: item.trust.type,
      name: item.trust.name,
      date: item.trust.date,
      familyName: item.trust.familyName,
      trustAka: item.trust.trustAka,
      signingDate: item.trust.signingDate,
      // trustees: item.trust.trustees.map((trustee: TTrusteeData) => ({
      //   id: trustee.id,
      //   type: trustee.type,
      //   fullName: trustee.fullName,
      //   fullAddress: trustee.fullAddress,
      // })),
    }));
  }, [tData]);

  return { trustData, totalPages, isLoading, error, fetchData };
}
