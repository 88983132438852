import { useCallback, useMemo, useState, useRef } from "react";

export type TClientData = {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  // docType: LegalDocTypes;
  // maritalStatus: MaritalStatus;
  // date: string;
  trustName: string;
  trustDate: string;
};

const PAGE_SIZE = 10;

export default function useGetClientData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [cData, setClientData] = useState<Array<any> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchData = useCallback(
    async (pageNumber: number, searchTerm: string | null) => {
      const fetchId = ++currentFetchId.current
      let url = `https://hometrustapi.azurewebsites.net/api/Clients?PageSize=${PAGE_SIZE}&PageNumber=${pageNumber}`;

      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      setIsLoading(true);
      setError(null);

      console.log("getting clients");
      try {
        const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
          method: "GET",
        });

        console.log(response);
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setClientData(responsejson.clients);
          console.log(responsejson);
          const totalEntries: number = responsejson.metaData.totalEntries;
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoading(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
    },
    []
  );

  const clientData = useMemo(() => {
    if (cData == null) {
      return null;
    }
    return cData.map(
      (client: {
        id: any;
        firstName: string;
        lastName: string;
        address: string;
        city: string;
        state: string;
        zip: string;
        trustName: string;
        trustDate: string;
        // dateCreated: string;
      }): TClientData => {
        // const dateCreated = new Date(client.dateCreated);
        return {
          id: client.id,
          name: client.firstName + " " + client.lastName,
          address: client.address,
          city: client.city,
          state: client.state,
          zip: client.zip,
          // docType: LegalDocTypes.WILL,
          // maritalStatus: MaritalStatus.MARRIED,
          trustName: client.trustName,
          trustDate: client.trustDate,
          // date: dateCreated.toLocaleDateString(),
        };
      }
    );
  }, [cData]);

  return { clientData, totalPages, isLoading, error, fetchData };
}
