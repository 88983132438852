export enum UrlPaths {
  Dashboard = "/dashboard",
  Notifications = "/notifications",
  Trusts = "/trusts",
  ViewTrust = "/trusts/",
  NewTrust = "/trusts/new_trust",
  EditTrust = "/trusts/edit/",
  Clients = "/clients",
  ViewClient = "/client/",
  NewClient = "/client/new_client",
  EditClient = "/client/edit/",
  Appointments = "/appointments",
  Calendar = "/calendar",
  Messages = "/messages",
  Payments = "/payments",
  Templates = "/templates",
  Home = "/",
  Register = "/register",
  ForgotPassword = "/forgot_password",
}

export enum CustomerUrlPaths {
  CustomerDashboard = "/customer/dashboard",
  Profile = "/customer/profile",
  Documents = "/customer/documents",
  RequestService = "/customer/request_service",
  ContactAgent = "/customer/contact_agent",
}

export enum Role {
  ADMIN = "Admin",
  EMPLOYEE = "Employee",
  USER = "User",
}

export enum LegalDocTypes {
  WILL = "Will",
  TRUST = "Trust",
  IMAGE = "image/png",
  TEXT = "text/rtf",
  PDF = "application/pdf",
  EML = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export enum MaritalStatus {
  MARRIED = "Married",
  DIVORCED = "Divorced",
  SEPERATED = "Seperated",
  WIDOW = "Widow",
  WIDOWER = "Widower",
  SINGLE = "Single",
}

export enum HTTPMethods {
  GET = "GET",
  DELETE = "DELETE",
  POST = "POST",
  PUT = "PUT",
}

export type TFileData = {
  id: number;
  clientId: number;
  name: string;
  type: string;
  category: string;
  size: number;
  notes: string;
  file: string;
};

export enum UploadDestinations {
  TEMPLATES = "/api/Templates",
  CLIENT_FILES = "/api/ClientFiles",
}

export enum TrustTypes {
  SPT = "SPT",
  CPT = "CPT",
  TA = "TA",
}

export enum TrusteeTypes {
  SPT = "Trustee",
  CPT1 = "Co-Trustee 1",
  CPT2 = "Co-Trustee 2",
}

export enum SuccessorTypes {
  Successor1 = "Successor Trustee 1",
  Successor2 = "Successor Trustee 2",
  Successor3 = "Successor Trustee 3",
}

export enum TemplateTypes {
  VialofLife = "Vial of Life",
  BinderBasics = "Binder Basics",
  AveryLabels = "Avery Labels",
  AveryLabelsSPT = "Avery Labels SPT",
  AveryLabelsCPT = "Avery Labels CPT",
  TrustTransferDeed = "Trust Transfer Deed",
  TrustCertificate = "Trust Certificate",
  CPT = "Community Property Trust",
  SPT = "Separate Property Trust",
  TA = "Trust Administration",
  LoveLetter = "Love Letter",
  TrustCertificateCPT = "Trust Certificate CPT",
  TrustCertificateSPT = "Trust Certificate SPT",
  TrustTransferDeedCPT = "Trust Transfer Deed CPT",
  TrustTransferDeedSPT = "Trust Transfer Deed SPT",
  LoveLetterCPT = "Love Letter CPT",
  LoveLetterSPT = "Love Letter SPT",
}

export enum TemplateDownloadEndpoints {
  VialofLife = "/api/Templates/download/vialoflife",
  BinderBasics = "/api/Templates/download/binderbasics",
  AveryLabelsSPT = "/api/Templates/download/averylabels/spt",
  AveryLabelsCPT = "/api/Templates/download/averylabels/cpt",
  TrustTransferDeed = "/api/Templates/download/trusttransferdeed",
  TrustCertificate = "/api/Templates/download/trustcertificate",
  CPT = "/api/Templates/download/cpt",
  SPT = "/api/Templates/download/spt",
  TA = "/api/Templates/download/ta",
  LoveLetter = "/api/Templates/download/loveletter",
  TrustCertificateCPT = "/api/Templates/download/trustcertificate/cpt",
  TrustCertificateSPT = "/api/Templates/download/trustcertificate/spt",
  TrustTransferDeedCPT = "/api/Templates/download/trusttransferdeed/cpt",
  TrustTransferDeedSPT = "/api/Templates/download/trusttransferdeed/spt",
  LoveLetterCPT = "/api/Templates/download/loveletter/cpt",
  LoveLetterSPT = "/api/Templates/download/loveletter/spt",
}

export enum TemplateUploadEndpoints {
  VialofLife = "/api/Templates/upload/vialoflife",
  BinderBasics = "/api/Templates/upload/binderbasics",
  AveryLabelsSPT = "/api/Templates/upload/averylabels/spt",
  AveryLabelsCPT = "/api/Templates/upload/averylabels/cpt",
  TrustTransferDeed = "/api/Templates/upload/trusttransferdeed",
  TrustCertificate = "/api/Templates/upload/trustcertificate",
  CPT = "/api/Templates/upload/cpt",
  SPT = "/api/Templates/upload/spt",
  TA = "/api/Templates/upload/ta",
  LoveLetter = "/api/Templates/upload/loveletter",
  TrustCertificateCPT = "/api/Templates/upload/trustcertificate/cpt",
  TrustCertificateSPT = "/api/Templates/upload/trustcertificate/spt",
  TrustTransferDeedCPT = "/api/Templates/upload/trusttransferdeed/cpt",
  TrustTransferDeedSPT = "/api/Templates/upload/trusttransferdeed/spt",
  LoveLetterCPT = "/api/Templates/upload/loveletter/cpt",
  LoveLetterSPT = "/api/Templates/upload/loveletter/spt",
}
