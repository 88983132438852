import { useCallback, useMemo, useState, useRef } from "react";

export type TClientMessages = {
  id: number
  clientId: number;
  clientRead: boolean;
  internalRead: false;
  sender: string;
  text: string;
  threadId: number;
  timestamp: string;
};

export default function useGetClientMessages() {
  const [isLoadingClientMessages, setIsLoadingClientMessages] = useState<boolean>(false);
  const [messageError, setError] = useState<any>(null);
  const [cData, setClientThreadData] = useState<Array<any> | null>(null);
  const currentFetchId = useRef(0)

  const fetchClientMessages = useCallback(
    async (id: number | undefined) => {
      const fetchId = ++currentFetchId.current
      let url = `https://hometrustapi.azurewebsites.net/api/Messages?clientId=${id}`;

      if (!id){
        return null
      }

      setIsLoadingClientMessages(true);
      setError(null);

      try {
        const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setClientThreadData(responsejson);
          setIsLoadingClientMessages(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoadingClientMessages(false);
      }
    },
    []
  );

  const clientMessages = useMemo(() => {
    if (cData === null) {
      return [];
    }
    if (cData.length > 0){
      return cData;
    }
    return []
  }, [cData]);

  return { clientMessages, isLoadingClientMessages, messageError, fetchClientMessages };
}
