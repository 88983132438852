import { Col, Form, Row } from "react-bootstrap";
import { TDirectivesData, TTrustsData } from "../../../utilities/trustTypes";
import { getDefaultDirectivesData } from "../../../utilities/defaultDataTypes";
import styled from "styled-components";

const StyledFormText = styled(Form.Text)`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const defaultDirectivesData: TDirectivesData =
  getDefaultDirectivesData();

export default function Directives({
  formData,
  setFormData,
  arrayPosition,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  arrayPosition: number;
  readOnly?: boolean;
}) {
  const updateCurrentDirective = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TDirectivesData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    const updatedDirectives = [...formData.directives];
    updatedDirectives[arrayPosition] = {
      ...updatedDirectives[arrayPosition],
      [key]: value,
    };

    if (arrayPosition === 1) {
      setFormData({
        ...formData,
        directives: updatedDirectives,
        trust: {
          ...formData.trust,
          financialInstitutions: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        directives: updatedDirectives,
      });
    }
  };

  const getDirectiveLabel = (index: number) => {
    switch (index) {
      case 0:
        return "Home and Contents";
      case 1:
        return "Banks, Credit Unions, Brokerage Firms, Securities Firms";
      case 2:
        return "Vehicles, Pets, Collections, Jewelry, Coins, Guns, Musical Instruments, Timeshares";
      default:
        return;
    }
  };

  return (
    <Row>
      <Col>
        <Form.Group
          className="mb-3"
          controlId={`directive${arrayPosition + 1}Description`}
        >
          <Form.Label>Directive {arrayPosition + 1}</Form.Label>
          <StyledFormText className="d-block">
            {getDirectiveLabel(arrayPosition)}
          </StyledFormText>
          <Form.Control
            name={`directive${arrayPosition + 1}`}
            as="textarea"
            rows={3}
            value={formData.directives?.[arrayPosition]?.description || ""}
            onChange={(e) => updateCurrentDirective(e, "description")}
            readOnly={readOnly}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
