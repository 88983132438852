import { useState } from "react";
import { useLocation } from "wouter";
import { UrlPaths } from "../../utilities/types";

export type TTextData = {
    clientId: number,
    threadId: number,
    text: string,
}

export default function usePostNewClient() {
  const [, setLocation] = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const postMessage = async (textData: TTextData) => {
    const url = "https://hometrustapi.azurewebsites.net/api/Messages";

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "POST",
        body: JSON.stringify(textData),
      });

      if (!response.ok) {
        throw new Error("Server returned error code: " + response.status);
      }
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
    setIsLoading(false);
    setLocation(UrlPaths.Messages);
  };

  return { postMessage, isLoading, error };
}
