import { Col, Form, Row } from "react-bootstrap";
import { TTrustData, TTrustsData } from "../../../utilities/trustTypes";
import { getDefaultTrustData } from "../../../utilities/defaultDataTypes";
import styled from "styled-components";

const StyledHeadlineH5 = styled.h5`
  color: #4c5637;
  font-weight: bold;
`;

export const defaultTrustData: TTrustData = getDefaultTrustData();
const currentYear = new Date().getFullYear();

const updateCheckboxValue = (
  currentValues: string,
  checkboxValue: string,
  isChecked: boolean
): string => {
  const valuesArray = currentValues.split(",").filter(Boolean);

  if (isChecked) {
    if (!valuesArray.includes(checkboxValue)) {
      valuesArray.push(checkboxValue);
    }
  } else {
    const updatedValuesArray = valuesArray.filter((v) => v !== checkboxValue);
    return updatedValuesArray.join(",");
  }

  return valuesArray.join(",");
};

export default function Trust({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentTrust = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TTrustData
  ) => {
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }

    if (!formData.trust) return;

    const target = event.currentTarget as HTMLInputElement;
    const checkboxValue = target.value;
    const isChecked = target.checked;

    let updatedValue: string | boolean;

    if (key === "perStirpesOrCapita") {
      updatedValue = updateCheckboxValue(
        formData.trust.perStirpesOrCapita || "",
        checkboxValue,
        isChecked
      );
    } else {
      updatedValue = target.value;
    }

    const updatedTrust = {
      ...formData.trust,
      [key]: updatedValue,
    };

    setFormData({
      ...formData,
      trust: updatedTrust,
    });
  };

  return (
    <>
      <Row>
        <Col xs={7}>
          <Form.Group className="mb-3" controlId="newTrustName">
            <Form.Label>Trust Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={formData.trust?.name}
              onChange={(target) => updateCurrentTrust(target, "name")}
            />
          </Form.Group>
        </Col>
        <Col xs={5}>
          <Form.Group className="mb-3" controlId="newTrustAKA">
            <Form.Label>Trust AKA</Form.Label>
            <Form.Control
              name="nameAKA"
              type="text"
              value={formData.trust?.trustAka}
              onChange={(target) => updateCurrentTrust(target, "trustAka")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg="2">
          <Form.Group className="mb-3" controlId="newTrustDate">
            <Form.Label>Trust Date</Form.Label>
            <Form.Control
              name="date"
              type="date"
              value={formData.trust?.date}
              onChange={(target) => updateCurrentTrust(target, "date")}
            />
          </Form.Group>
        </Col>
        <Col xs lg="2">
          <Form.Group className="mb-3" controlId="newTrustYear">
            <Form.Label>Trust Year</Form.Label>
            <Form.Control
              name="year"
              type="number"
              value={formData.trust?.year || currentYear}
              onChange={(e) => updateCurrentTrust(e, "year")}
              min={1930}
            />
          </Form.Group>
        </Col>
        {/* <Col className="col-auto">
          <Form.Group controlId="newTrustType">
            <Form.Label className="mx-2">Trust Type</Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="type"
                type="radio"
                label="CPT"
                value="CPT"
                checked={formData.trust?.type === "CPT"}
                onChange={(target) => updateCurrentTrust(target, "type")}
              ></Form.Check>
              <Form.Check
                inline
                name="type"
                type="radio"
                label="SPT"
                value="SPT"
                checked={formData.trust?.type === "SPT"}
                onChange={(target) => updateCurrentTrust(target, "type")}
              ></Form.Check>
              <Form.Check
                inline
                name="type"
                type="radio"
                label="TA"
                value="TA"
                checked={formData.trust?.type === "TA"}
                onChange={(target) => updateCurrentTrust(target, "type")}
              ></Form.Check>
            </Form.Group>
          </Form.Group>
        </Col> */}
        <Col>
          <Form.Group className="mb-3" controlId="newTrustFamilyName">
            <Form.Label>Surname</Form.Label>
            <Form.Control
              name="familyName"
              type="text"
              value={formData.trust?.familyName}
              onChange={(target) => updateCurrentTrust(target, "familyName")}
            />
          </Form.Group>
        </Col>
        <Col className="col-auto">
          <Form.Group controlId="newTrustPerStirpesOrCapita">
            <Form.Label className="mx-2"></Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="perStirpesOrCapita"
                type="checkbox"
                label="Per Stirpes"
                value="Per Stirpes"
                checked={(formData.trust?.perStirpesOrCapita || "").includes(
                  "Per Stirpes"
                )}
                onChange={(event) =>
                  updateCurrentTrust(event, "perStirpesOrCapita")
                }
              ></Form.Check>
              <Form.Check
                inline
                name="perStirpesOrCapita"
                type="checkbox"
                label="Per Capita"
                value="Per Capita"
                checked={(formData.trust?.perStirpesOrCapita || "").includes(
                  "Per Capita"
                )}
                onChange={(event) =>
                  updateCurrentTrust(event, "perStirpesOrCapita")
                }
              ></Form.Check>
            </Form.Group>
          </Form.Group>
        </Col>
        <Col className="col-auto">
          <Form.Group controlId="newTrustRestated">
            <Form.Label className="mx-2">Restated</Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="restated"
                type="radio"
                label="Yes"
                value="true"
                checked={formData.trust?.restated === true}
                onChange={({ target: { value } }) => {
                  if (!formData.trust) return;
                  setFormData({
                    ...formData,
                    trust: { ...formData.trust, restated: true },
                  });
                }}
              ></Form.Check>
              <Form.Check
                inline
                name="restated"
                type="radio"
                label="No"
                value="false"
                checked={formData.trust?.restated === false}
                onChange={({ target: { value } }) => {
                  if (!formData.trust) return;
                  setFormData({
                    ...formData,
                    trust: { ...formData.trust, restated: false },
                  });
                }}
              ></Form.Check>
            </Form.Group>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <Form.Group controlId="newTrustIsSpecialNeeds">
            <Form.Label className="mx-2">Special Needs</Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="isSpecialNeeds"
                type="radio"
                label="Yes"
                value="true"
                checked={formData.trust?.isSpecialNeeds === true}
                onChange={({ target: { value } }) => {
                  if (!formData.trust) return;
                  setFormData({
                    ...formData,
                    trust: {
                      ...formData.trust,
                      isSpecialNeeds: true,
                    },
                  });
                }}
              ></Form.Check>
              <Form.Check
                inline
                name="isSpecialNeeds"
                type="radio"
                label="No"
                value="false"
                checked={formData.trust?.isSpecialNeeds === false}
                onChange={({ target: { value } }) => {
                  if (!formData.trust) return;
                  setFormData({
                    ...formData,
                    trust: {
                      ...formData.trust,
                      isSpecialNeeds: false,
                    },
                  });
                }}
              ></Form.Check>
            </Form.Group>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="mb-3"
            controlId="newTrustSpecialNeedsInstructions"
          >
            <Form.Label>Special Needs Instructions</Form.Label>
            <Form.Control
              name="specialNeedsInstructions"
              as="textarea"
              value={formData.trust?.specialNeedsInstructions}
              onChange={(target) =>
                updateCurrentTrust(target, "specialNeedsInstructions")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <StyledHeadlineH5>Beneficiaries</StyledHeadlineH5>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustChildren">
            <Form.Label>Beneficiary Names</Form.Label>
            <Form.Control
              name="children"
              as="textarea"
              rows={3}
              value={formData.trust?.children}
              onChange={(target) => updateCurrentTrust(target, "children")}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustChildrenInstructions">
            <Form.Label>Instructions for Beneficiaries</Form.Label>
            <Form.Control
              name="childrenInstructions"
              as="textarea"
              rows={3}
              value={formData.trust?.childrenInstructions}
              onChange={(target) =>
                updateCurrentTrust(target, "childrenInstructions")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group
            className="mb-3"
            controlId="newTrustRemainderBeneficiaries"
          >
            <Form.Label>Remainder Beneficiaries</Form.Label>
            <Form.Control
              name="remainderBeneficiaries"
              as="textarea"
              rows={3}
              value={formData.trust?.remainderBeneficiaries}
              onChange={(target) =>
                updateCurrentTrust(target, "remainderBeneficiaries")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <StyledHeadlineH5>Disinheriting</StyledHeadlineH5>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="newTrustOmittedPersons">
            <Form.Label>Omitted Persons</Form.Label>
            <Form.Control
              name="omittedPersons"
              type="text"
              value={formData.trust?.omittedPersons}
              onChange={(target) =>
                updateCurrentTrust(target, "omittedPersons")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <StyledHeadlineH5>
          Names of Banks and Financial Institutions
        </StyledHeadlineH5>
      </Row>
      <Row>
        <Col>
          <Form.Group
            className="mb-3"
            controlId="newTrustfinancialInstitutions"
          >
            <Form.Label>Banks and Financial Institutions</Form.Label>
            <Form.Control
              name="financialInstitutions"
              as="textarea"
              style={{ backgroundColor: "#e9ecef" }}
              value={formData.trust?.financialInstitutions}
              onChange={(target) =>
                updateCurrentTrust(target, "financialInstitutions")
              }
              readOnly
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
