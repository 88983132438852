import { TTrustsData } from "../../utilities/trustTypes";
import { TrustTypes } from "../../utilities/types";
import CPTTrustees from "../trusts/form_sub_components/CPT/CPTTrustees";
import CPTRealEstate from "../trusts/form_sub_components/CPT/CPTRealEstate";
import Successors from "../trusts/form_sub_components/Successors";
import Fees from "../trusts/form_sub_components/Fees";
import Trust from "../trusts/form_sub_components/Trust";
import SPTTrustees from "../trusts/form_sub_components/SPT/SPTTrustees";
import SPTRealEstate from "../trusts/form_sub_components/SPT/SPTRealEstate";
import Decedent from "../trusts/form_sub_components/TA/Decedent";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import Directives from "../trusts/form_sub_components/Directives";
import TATrustee from "../trusts/form_sub_components/TA/TATrustee";
import TARealEstate from "../trusts/form_sub_components/TA/TARealEstate";
import TATrust from "../trusts/form_sub_components/TA/TATrust";
import SigningDetails from "../trusts/form_sub_components/SigningDetails";
import Tasks from "../trusts/form_sub_components/Tasks";

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: #656e51;
  }
`;

const StyledTab = styled(Tab)`
  color: #4c5637;
  text-transform: none;
  font-weight: bold;

  &&.Mui-selected {
    color: #c18633;
    border-bottom: 2px solid #4c5637;
  }

  &:hover {
    color: #656e51;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #656e51;
    color: #f5f4ed;
    font-size: 1rem;
    padding: 6px 12px;
    text-transform: capitalize;
    width: 11%;

    &:hover {
      background-color: #4c5637;
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type TrustFormTabsProps = {
  formData: TTrustsData;
  setFormData: React.Dispatch<React.SetStateAction<TTrustsData>>;
  readOnly?: boolean;
  tabValue: number;
  setTabValue: (value: number) => void;
  apiFunction?: (trustsData: TTrustsData) => void;
  addTrustee: () => void;
  addSuccessors: () => void;
  addDirectives: () => void;
  isSuccessorAddMoreHidden: boolean;
  isTrusteeAddMoreHidden: boolean;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function TrustFormTabs({
  formData,
  setFormData,
  readOnly = false,
  tabValue,
  setTabValue,
  apiFunction,
  addTrustee,
  addSuccessors,
  addDirectives,
  isSuccessorAddMoreHidden,
  isTrusteeAddMoreHidden,
}: TrustFormTabsProps) {
  const trustType = formData.trust?.type;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Row>
        <h4>
          {`${formData.trust.name} - ${formData.trust.type}` ||
            "Trust Information"}
        </h4>
      </Row>
      <StyledTabs value={tabValue} onChange={handleChange} variant="scrollable">
        {trustType === TrustTypes.CPT && [
          <StyledTab key={`CPT-0`} value={0} label="Co-Trustees" />,
          <StyledTab key={`CPT-1`} value={1} label="Successor Trustees" />,
          <StyledTab key={`CPT-2`} value={2} label="Directives" />,
          <StyledTab key={`CPT-3`} value={3} label="Trust Information" />,
          <StyledTab key={`CPT-4`} value={4} label="Real Estate" />,
          <StyledTab key={`CPT-5`} value={5} label="Fees" />,
          <StyledTab key={`CPT-6`} value={6} label="Signing Details" />,
          <StyledTab key={`CPT-7`} value={7} label="Tasks" />,
        ]}

        {trustType === TrustTypes.SPT && [
          <StyledTab key={`SPT-0`} value={0} label="Trustee" />,
          <StyledTab key={`SPT-1`} value={1} label="Successor Trustees" />,
          <StyledTab key={`SPT-2`} value={2} label="Directives" />,
          <StyledTab key={`SPT-3`} value={3} label="Trust Information" />,
          <StyledTab key={`SPT-4`} value={4} label="Real Estate" />,
          <StyledTab key={`SPT-5`} value={5} label="Fees" />,
          <StyledTab key={`SPT-6`} value={6} label="Signing Details" />,
          <StyledTab key={`SPT-7`} value={7} label="Tasks" />,
        ]}
        {trustType === TrustTypes.TA && [
          <StyledTab key={`TA-0`} value={0} label="Trust Information" />,
          <StyledTab key={`TA-1`} value={1} label="Acting Trustee" />,
          <StyledTab key={`TA-2`} value={2} label="Decedent" />,
          <StyledTab key={`TA-3`} value={3} label="Real Estate" />,
          <StyledTab key={`TA-4`} value={4} label="Fees" />,
          <StyledTab key={`TA-5`} value={5} label="Signing Details" />,
          <StyledTab key={`TA-6`} value={6} label="Tasks" />,
        ]}
      </StyledTabs>
      {trustType === TrustTypes.CPT && (
        <>
          <TabPanel value={tabValue} index={0}>
            <fieldset disabled={readOnly}>
              {formData.trustees &&
                Object.entries(formData.trustees).map(([_trtee, _], i) => (
                  <CPTTrustees
                    key={i}
                    arrayPosition={i}
                    formData={formData}
                    setFormData={setFormData}
                    readOnly={readOnly}
                  />
                ))}
              <Row className="justify-content-center">
                {apiFunction != null && !isTrusteeAddMoreHidden && (
                  <StyledButton onClick={addTrustee}>Add More</StyledButton>
                )}
              </Row>
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <fieldset disabled={readOnly}>
              {formData.successors &&
                Object.entries(formData.successors).map(([_suc, _], i) => (
                  <Successors
                    key={i}
                    arrayPosition={i}
                    formData={formData}
                    setFormData={setFormData}
                    readOnly={readOnly}
                  />
                ))}
              <Row className="justify-content-center">
                {apiFunction != null && !isSuccessorAddMoreHidden && (
                  <StyledButton onClick={addSuccessors}>Add More</StyledButton>
                )}
              </Row>
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <fieldset disabled={readOnly}>
              {formData.directives && formData.directives.length > 0 ? (
                formData.directives.map((directive, i) => (
                  <Directives
                    key={i}
                    arrayPosition={i}
                    formData={formData}
                    setFormData={setFormData}
                    readOnly={readOnly}
                  />
                ))
              ) : (
                <p>
                  {!readOnly
                    ? `Click "Add More" to create a Directive.`
                    : `Click "Edit" to create a Directive.`}
                </p>
              )}
              <Row className="justify-content-center">
                {!readOnly && (
                  <StyledButton onClick={addDirectives}>Add More</StyledButton>
                )}
              </Row>
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <fieldset disabled={readOnly}>
              {formData.trust && (
                <Trust
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <fieldset disabled={readOnly}>
              {formData.realEstate && (
                <CPTRealEstate
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <fieldset disabled={readOnly}>
              {formData.fees && (
                <Fees
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <fieldset disabled={readOnly}>
              {formData.realEstate && (
                <SigningDetails
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={7}>
            <fieldset disabled={readOnly}>
              {formData.tasks && (
                <Tasks
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
        </>
      )}
      {trustType === TrustTypes.SPT && (
        <>
          <TabPanel value={tabValue} index={0}>
            <fieldset disabled={readOnly}>
              {formData.trustees &&
                Object.entries(formData.trustees).map(([_trtee, _], i) => (
                  <SPTTrustees
                    key={i}
                    arrayPosition={i}
                    formData={formData}
                    setFormData={setFormData}
                    readOnly={readOnly}
                  />
                ))}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <fieldset disabled={readOnly}>
              {formData.successors &&
                Object.entries(formData.successors).map(([_suc, _], i) => (
                  <Successors
                    key={i}
                    arrayPosition={i}
                    formData={formData}
                    setFormData={setFormData}
                    readOnly={readOnly}
                  />
                ))}
              <Row className="justify-content-center">
                {apiFunction != null && !isSuccessorAddMoreHidden && (
                  <StyledButton onClick={addSuccessors}>Add More</StyledButton>
                )}
              </Row>
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <fieldset disabled={readOnly}>
              {formData.directives && formData.directives.length > 0 ? (
                formData.directives.map((directive, i) => (
                  <Directives
                    key={i}
                    arrayPosition={i}
                    formData={formData}
                    setFormData={setFormData}
                    readOnly={readOnly}
                  />
                ))
              ) : (
                <p>
                  {!readOnly
                    ? `Click "Add More" to create a Directive.`
                    : `Click "Edit" to create a Directive.`}
                </p>
              )}
              <Row className="justify-content-center">
                {!readOnly && (
                  <StyledButton onClick={addDirectives}>Add More</StyledButton>
                )}
              </Row>
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <fieldset disabled={readOnly}>
              {formData.trust && (
                <Trust
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>

          <TabPanel value={tabValue} index={4}>
            <fieldset disabled={readOnly}>
              {formData.realEstate && (
                <SPTRealEstate
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <fieldset disabled={readOnly}>
              {formData.fees && (
                <Fees
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <fieldset disabled={readOnly}>
              {formData.realEstate && (
                <SigningDetails
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={7}>
            <fieldset disabled={readOnly}>
              {formData.tasks && (
                <Tasks
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
        </>
      )}
      {trustType === TrustTypes.TA && (
        <>
          <TabPanel value={tabValue} index={0}>
            <fieldset disabled={readOnly}>
              {formData.trust && (
                <TATrust
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <fieldset disabled={readOnly}>
              {formData.trustees &&
                Object.entries(formData.trustees).map(([_trtee, _], i) => (
                  <TATrustee
                    key={i}
                    arrayPosition={i}
                    formData={formData}
                    setFormData={setFormData}
                    readOnly={readOnly}
                  />
                ))}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <fieldset disabled={readOnly}>
              {formData.decedent && (
                <Decedent
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <fieldset disabled={readOnly}>
              {formData.realEstate && (
                <TARealEstate
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <fieldset disabled={readOnly}>
              {formData.fees && (
                <Fees
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <fieldset disabled={readOnly}>
              {formData.realEstate && (
                <SigningDetails
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <fieldset disabled={readOnly}>
              {formData.tasks && (
                <Tasks
                  formData={formData}
                  setFormData={setFormData}
                  readOnly={readOnly}
                />
              )}
            </fieldset>
          </TabPanel>
        </>
      )}
    </>
  );
}
