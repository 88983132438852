import { Row, Button, Container, Col, Card } from "react-bootstrap";
import { FaFileWord } from "react-icons/fa6";
import { useParams } from "wouter";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import useGetTemplateData, {
  TTemplateData,
} from "../../hooks/templateHooks/useGetTemplateData";

import GenerateTemplateModal from "./GenerateTemplateModal";
import useGetDownloadTemplateData from "../../hooks/templateHooks/useGetDownloadTemplate";
import UploadTemplateModal from "./UploadTemplateModal";
import usePostUploadTemplateData from "../../hooks/templateHooks/usePostUploadTemplate";
import StandAloneTemplateDownloadModal from "./StandAloneTemplateDownloadModal";

const CardBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;
`;

export default function Templates() {
  const params = useParams();
  const { templateFileData, isLoading, error, fetchTemplates } =
    useGetTemplateData(true);

  const { fetchDownloadTemplate } = useGetDownloadTemplateData();
  const { uploadTemplate } = usePostUploadTemplateData();

  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDownloadAveryLabelModal, setShowDownloadAveryLabelModal] =
    useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const pageNumber = 1;
  const clientID = params.id ?? "";

  const toggleShowGenerate = () => setShowGenerateModal((p) => !p);
  const toggleShowUpload = () => setShowUploadModal((p) => !p);
  const toggleShowAveryLabelModal = () =>
    setShowDownloadAveryLabelModal((p) => !p);

  useEffect(() => {
    fetchTemplates(pageNumber, null, Number(clientID), null, null);
  }, [fetchTemplates, clientID]);

  const templateFileDataList = templateFileData ?? [];

  templateFileDataList.forEach((t) => {
    switch (t.name) {
      case "SPT":
        t.fullName = "Separate Property Trust";
        break;
      case "CPT":
        t.fullName = "Community Property Trust";
        break;
      case "TA":
        t.fullName = "Trust Administration";
    }
    return t;
  });

  const handleOpenModal = (docType: string) => {
    setSelectedTemplate(docType);
    toggleShowGenerate();
  };

  const handleOpenUploadModal = (docType: string) => {
    setSelectedTemplate(docType);
    toggleShowUpload();
  };

  const modalDocTypes = [
    "Avery Labels",
    "Trust Certificate",
    "Trust Transfer Deed",
    "Love Letter",
  ];

  const handleDownload = (docType: string) => {
    if (modalDocTypes.includes(docType)) {
      setSelectedTemplate(docType);
      toggleShowAveryLabelModal();
    } else {
      fetchDownloadTemplate(docType);
    }
  };

  return (
    <>
      <Container fluid className="row justify-content-center">
        <Row className="m-4 justify-content-center">
          <Row className="justify-content-center">
            <Col className="col-auto">
              <h2>Templates</h2>
            </Col>
          </Row>
        </Row>
        <Row xs={1} md={3}>
          {templateFileDataList.length > 0 ? (
            templateFileDataList.map((data: TTemplateData) => (
              <Col key={data.id}>
                <Card className="mb-2">
                  <Card.Header>
                    <CardBanner>
                      <div style={{ fontSize: "24px" }}>
                        {data.fullName ? data.fullName : data.name}
                      </div>
                      <FaFileWord size="2em" />
                    </CardBanner>
                  </Card.Header>
                  <Card.Body>
                    <p className="card-text">
                      Download, upload, or generate the {data.name} template for
                      a client.
                    </p>
                    <div className="button-container">
                      <Button
                        style={{ padding: "8px 16px", margin: "4px" }}
                        onClick={() => handleOpenModal(data.name)}
                      >
                        Generate
                      </Button>
                      <Button
                        style={{ padding: "8px 16px", margin: "4px" }}
                        onClick={() => handleDownload(data.name)}
                      >
                        Download
                      </Button>
                      <Button
                        style={{ padding: "8px 16px", margin: "4px" }}
                        onClick={() => handleOpenUploadModal(data.name)}
                      >
                        Upload
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : isLoading ? (
            <tr>
              <th>Loading... </th>
            </tr>
          ) : (
            <tr>
              <th>No data found</th>
            </tr>
          )}
        </Row>
        <Modal
          centered
          show={showGenerateModal}
          onHide={toggleShowGenerate}
          size="lg"
          contentClassName="generate-template-modal"
        >
          <GenerateTemplateModal
            handleClose={toggleShowGenerate}
            docName={selectedTemplate}
          />
        </Modal>
        <Modal
          centered
          show={showUploadModal}
          onHide={toggleShowUpload}
          size="lg"
          contentClassName="upload-template-modal"
        >
          <UploadTemplateModal
            docName={selectedTemplate}
            uploadTemplate={(templateType, file, selectedTrustType) =>
              uploadTemplate(templateType, file, selectedTrustType)
            }
          />
        </Modal>
        <Modal
          centered
          show={showDownloadAveryLabelModal}
          onHide={toggleShowAveryLabelModal}
          size="sm"
          contentClassName="select-trust-type-modal"
        >
          <StandAloneTemplateDownloadModal
            handleClose={toggleShowAveryLabelModal}
            selectedDocType={selectedTemplate}
          ></StandAloneTemplateDownloadModal>
        </Modal>
      </Container>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </>
  );
}
