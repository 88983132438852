import { useCallback, useMemo, useState, useRef } from "react";

export type TClientContactData = {
  clientContact: any;
  numInternalUnread: number;
  messages: any | null;
};

export default function useGetClientThreads() {
  const [isLoadingClientThreads, setIsLoadingClientThreads] = useState<boolean>(false);
  const [threadError, setError] = useState<any>(null);
  const [cData, setClientThreadData] = useState<Array<any> | null>(null);
  const currentFetchId = useRef(0)

  const fetchThreadData = useCallback(
    async (id: number | null) => {
      const fetchId = ++currentFetchId.current
      let url = `https://hometrustapi.azurewebsites.net/api/Messages/all`;

      if (id !== null){
        url = url + `?clientId=${id}`
      }
      setIsLoadingClientThreads(true);
      setError(null);

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();

        if (fetchId === currentFetchId.current) {
          setClientThreadData(responsejson);
          setIsLoadingClientThreads(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoadingClientThreads(false);
      }
    },
    []
  );

  const clientThreads = useMemo(() => {
    if (cData == null) {
      return null;
    }

    const getLatestTimestamp = (messages: any) => {
      if (!messages || messages.length === 0) {
        return 0; // or a suitable default value
      }
      return Math.max(...messages.map((message: any) => new Date(message.timestamp).getTime()));
    };

    // Sort clients based on the latest message timestamp
    return cData
      .map(client => ({
        clientContact: client.clientContact,
        numInternalUnread: client.numInternalUnread,
        messages: client.messages,
      }))
      .sort((a, b) => getLatestTimestamp(b.messages) - getLatestTimestamp(a.messages));
  }, [cData]);

  return { clientThreads, isLoadingClientThreads, threadError, fetchThreadData };
}
