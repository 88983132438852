import { useCallback, useState } from "react";

export default function useGetTrustTransferDeedTemplateData() {
  const [
    isLoadingTrustTransferDeedTemplate,
    setIsLoadingTrustTransferDeedTemplate,
  ] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchTrustTransferDeed = useCallback(
    async (trustId: string, trustName: string) => {
      let url = `https://hometrustapi.azurewebsites.net/api/Templates/generate/TrustTransferDeed/${trustId}`;

      setIsLoadingTrustTransferDeedTemplate(true);
      setError(null);

      console.log("getting Templates");
      try {
        await fetch(
          url, 
          { 
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }, 
            method: "GET" 
          })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${trustName}_TrustTransferDeed.docx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((error) => console.error("Error downloading file:", error));
      } catch (error: any) {
        setError(error);
        setIsLoadingTrustTransferDeedTemplate(false);
      }
    },
    []
  );

  return { isLoadingTrustTransferDeedTemplate, error, fetchTrustTransferDeed };
}
